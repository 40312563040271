














export default {
  name: 'Alumni',

  components: {},

  setup() {
    return {
      errors: []
    };
  }
};
