


















import { ref, PropType, watch } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { useUserState } from '@/store';

import Profile from '@/components/Profile.vue';
import Testimonial from '@/components/landing/Testimonial.vue';
import Hero from '@/components/landing/Hero.vue';

import { PATHWAY_OPTIONS, USER_ROLES, TIME_LINES, PROGRAMS } from '@/constants/landing';

export default {
  name: 'ParentLanding',

  components: {
    // Profile
    // Testimonial,
    // Hero
  },

  setup() {
    const starterEmail = ref('');
    const currentRole = ref('Student');
    const roleColor = ref('green');
    const roleLabel = ref('What employer projects would you like to explore?');
    const roles = ref(USER_ROLES);
    const { user } = useUserState(['user']);

    const changeRoleTo = (role: string) => {
      roles.value.student.show = true;
      roles.value.teacher.show = true;
      roles.value.school.show = true;
      roles.value.parent.show = true;
      roles.value.employer.show = true;
      roles.value.sponsor.show = true;

      switch (role) {
        case 'Student':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.student.color;
          roles.value.student.show = false;
          break;
        case 'Teacher':
          roleLabel.value = 'What pathways do you teach in your class?';
          roleColor.value = roles.value.teacher.color;
          roles.value.teacher.show = false;
          break;
        case 'School':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.school.color;
          roles.value.school.show = false;
          break;
        case 'Parent':
          roleLabel.value = 'What careers do you want your student to explore?';
          roleColor.value = roles.value.parent.color;
          roles.value.parent.show = false;
          break;
        case 'Employer':
          roleLabel.value = 'Check out employers in your industry';
          roleColor.value = roles.value.employer.color;
          roles.value.employer.show = false;
          break;
        case 'Sponsor':
          roleLabel.value = 'What workforce initiatives do you want to fund?';
          roleColor.value = roles.value.sponsor.color;
          roles.value.sponsor.show = false;
          break;
        default:
          break;
      }
      currentRole.value = role;
    };

    const pathwayPresets = ref(['All']);
    const handlePathwaysInput = () => {
      if (
        pathwayPresets.value.length > 1 &&
        pathwayPresets.value[pathwayPresets.value.length - 1] === 'All'
      )
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter === 'All');
      else if (pathwayPresets.value.length > 1)
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter !== 'All');
    };

    // watch(newpass => {
    //   window.videoask.loadEmbed({
    //     kind: 'widget',
    //     url: 'https://www.videoask.com/fsh9ziggj',
    //     options: {
    //       widgetType: 'VideoThumbnailWindow',
    //       text: 'Get Started',
    //       backgroundColor: '#000000',
    //       position: 'bottom-right'
    //     }
    //   });
    // });

    //     watch(pass, newpass => {
    //   if (newpass === invitePages[getInvitee.value].password) {
    //     (window as any).videoask.loadEmbed({
    //       kind: 'widget',
    //       url: 'https://www.videoask.com/f5my3e7yf',
    //       options: {
    //         widgetType: 'VideoThumbnailLarge',
    //         text: 'Got Qs?',
    //         backgroundColor: '#dedede',
    //         position: 'bottom-right'
    //       }
    //     });
    //   }
    // });

    return {
      errors: [],
      pathwaySearch: null,
      pathwayOptions: PATHWAY_OPTIONS,
      pathwayPresets,
      handlePathwaysInput,
      user,
      starterEmail,
      currentRole,
      changeRoleTo,
      roleLabel,
      roleColor,
      roles,
      snackbar: true,
      timeLines: TIME_LINES,
      programs: PROGRAMS,

      scopeTabs: null,
      scopeText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

      carouselColors: ['purple', 'green', 'blue', 'red', 'yellow', 'orange', 'pink'],
      carouselSlides: [
        'Publish projects to award internships',
        'Early workforce development that saves time',
        'Diversify your team with local students',
        'Make community impact the smart way',
        'Civic engagement with local residents'
      ]
    };
  }
};
