































































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Story',
  setup() {
    const teamMembers = [
      {
        name: 'Eric Reyes',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/EricReyes.png',
        description: ''
      },
      {
        name: 'Jerold Inocencio',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JeroldInocencio.png',
        description: ''
      },
      {
        name: 'Kura Peng',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KuraPeng.png',
        description: ''
      },
      {
        name: 'Parul Veda',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ParulVeda.png',
        description: ''
      },
      {
        name: 'Aaron Brown',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AaronBrown.png',
        description: ''
      },
      {
        name: 'Abizer Varawala',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AbizerVarawala.png',
        description: ''
      },
      {
        name: 'Ayan Shah',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyanShah.png',
        description: ''
      },
      {
        name: 'Huaxuan Yang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HuaxuanYang.png',
        description: ''
      },
      {
        name: 'Noah MacLean',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NoahMacLean.png',
        description: ''
      },
      {
        name: 'Prajit Saravanan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrajitSaravanan.png',
        description: ''
      },
      {
        name: 'Riddhi Bhandari',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiddhiBhandari.png',
        description: ''
      },
      {
        name: 'Samantha Wilcox',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SamanthaWilcox.png',
        description: ''
      },
      {
        name: 'Tim Deloney',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TimDeloney.png',
        description: ''
      },
      {
        name: 'Alexandra Arient',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlexandraArient.png',
        description: ''
      },
      {
        name: 'Areeba Asaduzzaman',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AreebaAsaduzzaman.png',
        description: ''
      },
      {
        name: 'Bridgette Lai',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/BridgetteLai.png',
        description: ''
      },
      {
        name: 'Devarsh Jayakrishnan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DevarshJayakrishnan.png',
        description: ''
      },
      {
        name: 'Eric Xie',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/EricXie.png',
        description: ''
      },
      {
        name: 'Erik Pedersen',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErikPedersen.png',
        description: ''
      },
      {
        name: 'Hannah Levin',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HannahLevin.png',
        description: ''
      },
      {
        name: 'Harsh Sheth',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HarshSheth.png',
        description: ''
      },
      {
        name: 'Kevin Dinh',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KevinDinh.png',
        description: ''
      },
      {
        name: 'Kyara Garner',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KyaraGarner.png',
        description: ''
      },
      {
        name: 'Lorenzo Uk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/LorenzoUk.png',
        description: ''
      },
      {
        name: 'Nick Falk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NickFalk.png',
        description: ''
      },
      {
        name: 'Sophia Zhu',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SophiaZhu.png',
        description: ''
      },
      {
        name: 'Stephanie Betancourt',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/StephanieBetancourt.png',
        description: ''
      },
      {
        name: 'Thryambak Ganapathy',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ThryambakGanapathy.png',
        description: ''
      },
      {
        name: 'Vaibhav Jayakumar',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/VaibhavJayakumar.png',
        description: ''
      },
      {
        name: 'Aadhvika Koundinya',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AadhvikaKoundinya.png',
        description: ''
      },
      {
        name: 'Adhvaith Ravi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AdhvaithRavi.png',
        description: ''
      },
      {
        name: 'Akheel Shaik',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AkheelShaik.png',
        description: ''
      },
      {
        name: 'Alec McGhie',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlecMcGhie.png',
        description: ''
      },
      {
        name: 'Alexis Quan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlexisQuan.png',
        description: ''
      },
      {
        name: 'Darius Stowe',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DariusStowe.png',
        description: ''
      },
      {
        name: 'Elon Hufana',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ElonHufana.png',
        description: ''
      },
      {
        name: 'Erin Lam',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErinLam.png',
        description: ''
      },
      {
        name: 'Johnathan Sutan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JohnathanSutan.png',
        description: ''
      },
      {
        name: 'Katrina Castro',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KatrinaCastro.png',
        description: ''
      },
      {
        name: 'Kelly Hu',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KellyHu.png',
        description: ''
      },
      {
        name: 'Khushi Matpadi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KhushiMatpadi.png',
        description: ''
      },
      {
        name: 'Marley Maduakolam',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/MarleyMaduakolam.png',
        description: ''
      },
      {
        name: 'Mrinmayee Reddy',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/MrinmayeeReddy.png',
        description: ''
      },
      {
        name: 'Nikhil Gupta',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NikhilGupta.png',
        description: ''
      },
      {
        name: 'Niya Panchumarthi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NiyaPanchumarthi.png',
        description: ''
      },
      {
        name: 'Nolan Tsung',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NolanTsung.png',
        description: ''
      },
      {
        name: 'Phoebe Bang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PhoebeBang.png',
        description: ''
      },
      {
        name: 'Pranav Sundar',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PranavSundar.png',
        description: ''
      },
      {
        name: 'Pranay Rishi Nalem',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PranayRishiNalem.png',
        description: ''
      },
      {
        name: 'Princess Johanna Eusantos',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrincessJohannaEusantos.png',
        description: ''
      },
      {
        name: 'Riya Lal',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiyaLal.png',
        description: ''
      },
      {
        name: 'Rohit Mamidipaka',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RohitMamidipaka.png',
        description: ''
      },
      {
        name: 'Stephanie Li',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/StephanieLi.png',
        description: ''
      },
      {
        name: 'Yulin Lin',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/YulinLin.png',
        description: ''
      },
      {
        name: 'Aishwarya Karthikraja',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AishwaryaKarthikraja.png',
        description: ''
      },
      {
        name: 'Ajane Chavez',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AjaneChavez.png',
        description: ''
      },
      {
        name: 'Alyssa Cheng',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlyssaCheng.png',
        description: ''
      },
      {
        name: 'Aman Nalem',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AmanNalem.png',
        description: ''
      },
      {
        name: 'Aprajit Vijayaraghavan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AprajitVijayaraghavan.png',
        description: ''
      },
      {
        name: 'Atharv Rajure',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AtharvRajure.png',
        description: ''
      },
      {
        name: 'Ayush Kansal',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyushKansal.png',
        description: ''
      },
      {
        name: 'Hoi Lam Ling',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HoiLamLing.png',
        description: ''
      },
      {
        name: 'Jacob Gardanier',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JacobGardanier.png',
        description: ''
      },
      {
        name: 'Joey Choi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JoeyChoi.png',
        description: ''
      },
      {
        name: 'Romney Cola',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RomneyCola.png',
        description: ''
      },
      {
        name: 'Shahir Ali',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ShahirAli.png',
        description: ''
      },
      {
        name: 'Tyler Wang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TylerWang.png',
        description: ''
      }
    ];
    return {
      teamMembers
    };
  }
});
